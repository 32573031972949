import React, { useEffect, useState, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import DrawerNavigation from './DrawerNavigation';
import { useAuth } from './Auth/AuthContext';
import { getAllQuotations } from '../providers/http'; // Function to fetch all quotations
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const TableContainerStyled = styled(TableContainer)<{ component: typeof Paper }>({
  margin: '20px 0',
  borderRadius: '8px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
});

const TableHeaderCell = styled(TableCell)({
  backgroundColor: '#3f51b5',
  color: '#fff',
  fontWeight: 'bold',
});

const QuotationsList: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [quotations, setQuotations] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [selectedQuotation, setSelectedQuotation] = useState<any>(null);
  const [items, setItems] = useState<any[]>([]); // State for items

  const fetchData = useCallback(() => {
    setLoading(true);
    getAllQuotations(user?.companyId)
      .then((resp: any) => {
        console.log("getAllQuotations resp =>", resp);
        setQuotations(resp);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log("err =>", err);
        setLoading(false);
      });
  }, [user?.companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleViewDetails = (quotation: any) => {
    setSelectedQuotation(quotation);
    setItems(quotation.items || []); // Set items from the selected quotation
    setOpenDetailDialog(true);
  };

  const handleCloseDetailDialog = () => {
    setSelectedQuotation(null);
    setOpenDetailDialog(false);
    setItems([]); // Clear items when closing
  };

  return (
    <DrawerNavigation>
      <div>
        <Typography variant="h5" style={{ marginBottom: '20px', color: '#3f51b5', fontWeight: 'bold' }}>
          Quotations
        </Typography>
        <Button variant="contained" style={{ marginBottom: '20px', backgroundColor: '#3f51b5', color: '#fff' }} onClick={() => navigate('/input')}>
          Add New Quotation
        </Button>

        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainerStyled component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell>Quotation No</TableHeaderCell>
                  <TableHeaderCell>Date</TableHeaderCell>
                  <TableHeaderCell>Client</TableHeaderCell>
                  <TableHeaderCell>Project Name</TableHeaderCell>
                  <TableHeaderCell>Total Amount</TableHeaderCell>
                  <TableHeaderCell>Actions</TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {quotations.map((quotation) => (
                  <TableRow key={quotation.id}>
                    <TableCell>{quotation.quotationNo}</TableCell>
                    <TableCell>{quotation.date}</TableCell>
                    <TableCell>{quotation.quotationTo}</TableCell>
                    <TableCell>{quotation.projectName}</TableCell>
                    <TableCell>{quotation.totalAmount}</TableCell>
                    <TableCell>
                      <Button variant="outlined" onClick={() => handleViewDetails(quotation)}>
                        View Details
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainerStyled>
        )}

        {/* Quotation Details Dialog */}
        <Dialog 
        open={openDetailDialog} 
        onClose={handleCloseDetailDialog}
        PaperProps={{
          style: { width: '100%', maxWidth: '150%' } // Adjust the width as needed
        }}>
          <DialogTitle>Quotation Details</DialogTitle>
          <DialogContent>
            {selectedQuotation && (
              <div>
                <Typography variant="h6">Quotation No: {selectedQuotation.quotationNo}</Typography>
                <Typography>Date: {selectedQuotation.date}</Typography>
                <Typography>To: {selectedQuotation.quotationTo}</Typography>
                <Typography>Project Name: {selectedQuotation.projectName}</Typography>
                <Typography>Contact Person: {selectedQuotation.contactPerson}</Typography>
                <Typography>Contact No: {selectedQuotation.contactNo}</Typography>
                <Typography>Email: {selectedQuotation.emailIdCustomer}</Typography>
                <Typography>Quote Validity: {selectedQuotation.quoteValidity}</Typography>
                <Typography>Total Amount: {selectedQuotation.totalAmount}</Typography>
                <Typography>Total GST: {selectedQuotation.totalGST}</Typography>

                {/* Display quotation items */}
                <Typography variant="h6" style={{ marginTop: '20px' }}>Quotation Items</Typography>
                <TableContainerStyled component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableHeaderCell>Description</TableHeaderCell>
                        <TableHeaderCell>Unit</TableHeaderCell>
                        <TableHeaderCell>Quantity</TableHeaderCell>
                        <TableHeaderCell>Unit Price</TableHeaderCell>
                        <TableHeaderCell>Total Price</TableHeaderCell>
                        <TableHeaderCell>GST %</TableHeaderCell>
                        <TableHeaderCell>GST Amount</TableHeaderCell>
                        <TableHeaderCell>Amount</TableHeaderCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {items.map((item) => (
                        <TableRow key={item.id}>
                          <TableCell>{item.description}</TableCell>
                          <TableCell>{item.unit}</TableCell>
                          <TableCell>{item.qty}</TableCell>
                          <TableCell>{item.unitPrice}</TableCell>
                          <TableCell>{item.totalPrice}</TableCell>
                          <TableCell>{item.gstPercent}</TableCell>
                          <TableCell>{item.gstAmount}</TableCell>
                          <TableCell>{item.amount}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainerStyled>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDetailDialog} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </DrawerNavigation>
  );
};

export default QuotationsList;