import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import ItemList from './ItemList';
import Terms from './Terms';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import '../quotation.css';
import DrawerNavigation from './DrawerNavigation';

interface Item {
  sno: number;
  description: string;
  unit: string;
  qty: number;
  unitPrice: number;
  totalPrice: number;
  gstPercent: number;
  gstAmount: number;
  amount: number;
}

interface Details {
  quotationNo: string;
  date: string;
  quotationTo: string;
  projectName: string;
  contactPerson: string;
  proposalFor: string;
  contactNo: string;
  emailIdCustomer: string;
  quoteValidity: string;
}

interface EmpDetails {
  salesPerson: string;
  emailIdSales: string;
  contactNoSales: string;
}

interface QuotationPageProps {
  items: Item[];
  details: Details;
  empDetails: EmpDetails;
}

const numberToWords = (num: number): string => {
  const a = [
    '',
    'One', 'Two', 'Three', 'Four', 'Five',
    'Six', 'Seven', 'Eight', 'Nine', 'Ten',
    'Eleven', 'Twelve', 'Thirteen', 'Fourteen',
    'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen',
    'Nineteen',
  ];
  const b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

  const numToWords = (n: number): string => {
    if (n < 20) return a[n];
    if (n < 100) return b[Math.floor(n / 10)] + ' ' + a[n % 10];
    if (n < 1000) return a[Math.floor(n / 100)] + ' Hundred ' + numToWords(n % 100);
    if (n < 100000) return numToWords(Math.floor(n / 1000)) + ' Thousand ' + numToWords(n % 1000);
    if (n < 10000000) return numToWords(Math.floor(n / 100000)) + ' Lakh ' + numToWords(n % 100000);
    return numToWords(Math.floor(n / 10000000)) + ' Crore ' + numToWords(n % 10000000);
  };
  return numToWords(num).trim();
};

const QuotationPage: React.FC<QuotationPageProps> = ({ items, details, empDetails }) => {
  const printRef = useRef<HTMLDivElement>(null);
  const [itemsPerPage, setItemsPerPage] = useState<any>(items.length ? items.length : 10); // Maximum items per page
  const [isLoading, setIsLoading] = useState(false);

  const totalAmount = items.reduce((acc, item) => acc + Number(item.amount), 0);
  const totalGST = items.reduce((acc, item) => acc + Number(item.gstAmount), 0);
  const totalSum = totalAmount + totalGST;
  const totalSumInWords = numberToWords(totalSum);
  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handleGeneratePDF = async () => {
    setIsLoading(true);
    const input = printRef.current;
    const padding = 2;
    const pageWidth = 210;
    const pageHeight = 297;
    if (!input) return;
    const canvas = await html2canvas(input, { scale: 3, useCORS: true });
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', [pageWidth, pageHeight]);
    const availableWidth = 290;
    const availableHeight = 600;
    let imgWidth = availableWidth;
    let imgHeight = (canvas.height * imgWidth) / canvas.width;
    if (imgHeight > availableHeight) {
      imgHeight = availableHeight;
      imgWidth = (canvas.width * imgHeight) / canvas.height;
    }
    const xOffset = (pageWidth - imgWidth) / 2;
    const yOffset = 0;
    pdf.addImage(imgData, 'PNG', xOffset, yOffset, imgWidth, imgHeight);
    setTimeout(() => {
      setIsLoading(false);
    }, 1500);
    pdf.save('quotation.pdf');
  };

  return (
    <DrawerNavigation>
      <div>
        <div ref={printRef}>
          {Array.from({ length: totalPages }, (_, pageIndex) => (
            <div key={pageIndex} className={`container ${pageIndex > 0 ? 'page-break-before' : ''}`}>
              {pageIndex === 0 && <Header />}
              {pageIndex === 0 && (
                <div className="details">
                  <table>
                    <tbody>
                      {/* Details Table */}
                      {/* Similar to previous details table code */}
                    </tbody>
                  </table>
                </div>
              )}
              {/* Display only 12 items per page */}
              <ItemList items={items.slice(pageIndex * itemsPerPage, (pageIndex + 1) * itemsPerPage)} />
              {pageIndex === totalPages - 1 && (
                <>
                  {/* Only show footer and terms on the last page */}
                  <div className="total-section">
                    <table>
                      <tbody>
                        <tr>
                          <td colSpan={8} style={{ textAlign: 'right', backgroundColor: '#0a2990', color: 'white' }}>Sub Total (Rs)</td>
                          <td colSpan={3}>{totalAmount}</td>
                        </tr>
                        <tr>
                          <td colSpan={8} style={{ textAlign: 'right', backgroundColor: '#0a2990', color: 'white' }}>Tax (Rs)</td>
                          <td colSpan={3}>{totalGST}</td>
                        </tr>
                        <tr>
                          <td colSpan={8} style={{ textAlign: 'right', backgroundColor: '#0a2990', color: 'white' }}>Grand Total (Rs)</td>
                          <td colSpan={3}>{totalSum}</td>
                        </tr>
                        <tr>
                          <td colSpan={8} style={{ textAlign: 'right', backgroundColor: '#0a2990', color: 'white' }}>Amount in Words:</td>
                          <td colSpan={3}>{totalSumInWords}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="terms-container">
                    <Terms />
                  </div>
                  <Footer />
                </>
              )}
            </div>
          ))}
        </div>
        <center>
          <button onClick={handleGeneratePDF} style={{ marginTop: '20px', width: '50%', padding: '10px', borderRadius: 12, fontSize: 20 }}>
            {isLoading ? <div className="loader"></div> : <b>Download PDF</b>}
          </button>
        </center>
      </div>
    </DrawerNavigation>
  );
}

export default QuotationPage;