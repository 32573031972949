import React, { useEffect } from 'react';
import DrawerNavigation from './DrawerNavigation';
import { Container, Typography } from '@mui/material';
import { useAuth } from './Auth/AuthContext';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
} from 'recharts';

const Dashboard: React.FC = () => {
  const { user } = useAuth();
  
  useEffect(() => {
    console.log("user from Dashboard =>", user);
  }, []);
  
  // Demo data for bar chart
  const barData = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
  ];

  // Demo data for pie chart
  const pieData = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group D', value: 200 },
  ];

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <DrawerNavigation>
      <Container>
        <Typography variant="h4" gutterBottom>
          Welcome to the Dashboard
        </Typography>
        <Typography variant="body1">
        </Typography>
        
        {/* Bar Chart */}
        <Typography variant="h5" gutterBottom>
           Quotation Overview Chart
        </Typography>
        <BarChart
          width={1200}
          height={600}
          data={barData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="pv" fill="#8884d8" />
          <Bar dataKey="uv" fill="#82ca9d" />
        </BarChart>
       
      </Container>
    </DrawerNavigation>
  );
};

export default Dashboard;