import React from "react";
import "../styles.css";

const Terms: React.FC = () => (
  <div className="terms">
    <h1>Terms & Conditions</h1>
    <ol style={{marginLeft: '-2.5%'}}>
      <p>1. All prices include applicable taxes.</p>
      <p>2. Local items will be delivered within 4 to 6 working days.</p>
      <p>3. Imported items will be delivered within 4 to 6 working weeks.</p>
      <p>4. The client will provide power points at all necessary locations.</p>
      <p>
        5. The client will assign one resource for assistance with existing
        cabling routes and approval/IR of all work performed.
      </p>
      <p>
        6. Payment Terms will be 40% advance payment, 40% payment upon delivery,
        and 20% payment after completion of the job.
      </p>
      <p>
        7. Finishing after digging/laying of cable will not be in SEC's scope or
        shall be charged separately.
      </p>
      <p>
        8. The base pricing is set at 280 PKR/USD. Any changes in the forex rate
        will be applied at the time of payment.
      </p>
      <p>
        9. The proposal will be void after its validity period due to ongoing
        market instability and economic conditions.
      </p>
      <p>
        10. Syed Engineering Co. shall not be liable for any loss, damage,
        detention, or failure to perform due to causes beyond its control.
      </p>
      <p>
        11. SEC will not be liable for any damages, including direct, indirect,
        special, incidental, or consequential damages.
      </p>
      <p>
        12. The proposal is based on the initial site survey. Any changes beyond
        this will be charged at actual costs. Job and lot quantity estimations
        are mentioned.
      </p>
    </ol>
  </div>
);

export default Terms;
